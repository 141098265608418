import {
  ServiceLocation,
  ServicePayment,
  ServiceType,
  StaffMember,
} from '@wix/bookings-uou-types';
import { ServiceInfo } from '../../api/types';
import {
  ActionLabels,
  GetServiceResponse,
  Header as FormHeader,
  PaymentOptions,
  Rate,
  Schedule,
  ScheduleStatus,
} from '@wix/ambassador-services-catalog-server/http';
import {
  CommonImage,
  Form,
} from '../../types/ambassador/bookings/ambassador-services-catalog';
import {
  mapServiceLocations,
  mapServicePayment,
} from '@wix/bookings-uou-mappers';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import { BookingsLineItemOption } from '@wix/bookings-checkout-api';
import {
  FormNestedSlot,
  FormSelectedSlot,
} from '../../types/formSelectedSlots';
import {
  BookedLocation,
  LocationType,
  SelectedPaymentOption,
} from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';

export type Location = {
  id?: string | null;
  name?: string | null;
  address?: string | null;
  locationType: LocationType;
};

export type Service = {
  id: string;
  name: string;
  rate: Rate;
  staffMembers: StaffMember[];
  location: Location;
  isPendingApprovalFlow: boolean;
  isWaitingListFlow: boolean;
  paymentTypes: SelectedPaymentOption[];
  type: ServiceType;
  scheduleId: string;
  formFromCatalog: Form;
  formHeader: FormHeader;
  totalNumberOfSessions: number;
  payment: ServicePayment;
  actionLabels: ActionLabels;
  maxNumberOfParticipants: number;
  maxNumberOfParticipantsWithoutPP?: number;
  images?: CommonImage[];
  policyDescription?: string;
  includesConferenceOption?: boolean;
  availableSpots?: number;
};

const getOccupiedSpotsForSlotFromCart = ({
  sessionId,
  scheduleId,
  bookingsLineItemOptions,
}: {
  sessionId?: string;
  scheduleId?: string;
  bookingsLineItemOptions: BookingsLineItemOption[];
}) => {
  return bookingsLineItemOptions.reduce((acc, next) => {
    if (
      (sessionId && next.slot?.sessionId === sessionId) ||
      (scheduleId && next.scheduleId === scheduleId)
    ) {
      return acc + (next.numberOfParticipants || 1);
    }
    return acc;
  }, 0);
};

export const mapCatalogServiceToService = ({
  serviceId,
  serviceInfo,
  formSelectedSlot,
  memberships,
  numberOfSessions,
  isDynamicPriceEnabled = true,
  bookingsLineItemOptions,
  isCart = false,
  slot,
}: {
  serviceId: string;
  serviceInfo: ServiceInfo;
  formSelectedSlot: FormSelectedSlot;
  memberships?: ListEligibleMembershipsResponse;
  numberOfSessions: number;
  isDynamicPriceEnabled?: boolean;
  bookingsLineItemOptions?: BookingsLineItemOption[];
  isCart?: boolean;
  slot: FormNestedSlot;
}): Service => {
  const activeSchedule = getActiveSchedule(serviceInfo.service);
  const scheduleId = activeSchedule!.id!;

  const serviceType = getServiceType(activeSchedule);

  const slotLocation: Maybe<BookedLocation> = slot?.location;
  const serviceLocation: ServiceLocation =
    mapServiceLocations(activeSchedule)[0];

  const isCourse = serviceType === ServiceType.COURSE;

  const location = isCourse
    ? mapServiceLocationToLocation(serviceLocation)
    : mapSlotLocationToLocation(slotLocation);

  const paymentOptions: PaymentOptions =
    serviceInfo.service.service?.paymentOptions!;
  const paymentTypes: SelectedPaymentOption[] = mapPaymentOptionsToPaymentTypes(
    paymentOptions,
    memberships,
  );

  const payment = mapServicePayment(serviceInfo.service);

  const staffMembers = serviceInfo.staffMembers
    .filter((staffMember) =>
      serviceType === ServiceType.COURSE
        ? true
        : slot?.resource?.id === staffMember.id,
    )
    .map((staffMember) => {
      return {
        id: staffMember.id!,
        name: staffMember.name,
      };
    });

  const occupiedSpotsFromCart = bookingsLineItemOptions
    ? getOccupiedSpotsForSlotFromCart({
        scheduleId,
        sessionId: slot?.sessionId,
        bookingsLineItemOptions,
      })
    : 0;

  const availableSpots = Math.max(
    0,
    (formSelectedSlot.openSpots ?? 0) - occupiedSpotsFromCart,
  );
  const maxParticipantsPerBooking =
    serviceInfo?.service?.service?.policy?.maxParticipantsPerBooking ?? 0;

  const maxNumberOfParticipants = getMaxNumberOfParticipants({
    availableSpots,
    maxParticipantsPerBooking,
    memberships,
  });

  const maxNumberOfParticipantsWithoutPP = getMaxNumberOfParticipants({
    availableSpots,
    maxParticipantsPerBooking,
  });

  const rate = activeSchedule.rate!;

  const formHeader = serviceInfo.service.form?.header!;

  const isPendingApprovalFlow =
    !!serviceInfo.service.service?.policy?.bookingsApprovalPolicy
      ?.isBusinessApprovalRequired;

  const formFromCatalog = serviceInfo.service.form;

  const name = getServiceName(serviceInfo.service);

  const images = isCart
    ? getServiceImagesWithAltText(serviceInfo.service)
    : undefined;

  return {
    id: serviceId,
    name,
    formHeader,
    rate,
    payment,
    type: serviceType,
    staffMembers,
    paymentTypes,
    location,
    totalNumberOfSessions: numberOfSessions,
    isPendingApprovalFlow,
    isWaitingListFlow:
      !!serviceInfo.service.service?.policy?.waitingListPolicy?.isEnabled,
    scheduleId,
    actionLabels: serviceInfo.service.form!.actionLabels!,
    maxNumberOfParticipants,
    ...(isDynamicPriceEnabled
      ? {
          maxNumberOfParticipantsWithoutPP,
        }
      : {}),
    formFromCatalog: formFromCatalog!,
    ...(images ? { images } : {}),
    policyDescription: serviceInfo.service.service?.policy?.cancellationPolicy,
    includesConferenceOption:
      !!serviceInfo.service.service?.includeConferenceOption,
    availableSpots,
  };
};

export const getActiveSchedule = (service: GetServiceResponse): Schedule => {
  return (
    service?.schedules?.find(
      (schedule) => schedule.status === ScheduleStatus.CREATED,
    ) || service?.schedules?.[0]!
  );
};

export const getServiceType = (schedule: Schedule): ServiceType => {
  return (
    (schedule?.tags?.find(
      (tag: string) =>
        tag === ServiceType.COURSE ||
        tag === ServiceType.GROUP ||
        tag === ServiceType.INDIVIDUAL,
    ) as ServiceType) || ServiceType.INDIVIDUAL
  );
};

const mapPaymentOptionsToPaymentTypes = (
  paymentOptions: PaymentOptions,
  memberships?: ListEligibleMembershipsResponse,
): SelectedPaymentOption[] => {
  const paymentTypes = [];
  if (paymentOptions?.wixPayOnline) {
    paymentTypes.push(SelectedPaymentOption.ONLINE);
  }
  if (paymentOptions?.wixPayInPerson) {
    paymentTypes.push(SelectedPaymentOption.OFFLINE);
  }
  if (memberships?.eligibleMemberships?.length && paymentOptions?.wixPaidPlan) {
    paymentTypes.push(SelectedPaymentOption.MEMBERSHIP);
  }
  return paymentTypes;
};

const mapServiceLocationToLocation = (location: ServiceLocation): Location => {
  return {
    id: location.businessLocation?.id,
    name: location.businessLocation?.name,
    ...{
      address:
        location?.locationText ??
        location?.businessLocation?.address?.formattedAddress,
    },
    locationType: location?.type as any,
  };
};

const mapSlotLocationToLocation = (location?: BookedLocation): Location => {
  return {
    id: location?.id,
    name: location?.name,
    address: location?.formattedAddress,
    locationType: location!.locationType!,
  };
};

const getMaxNumberOfParticipants = ({
  availableSpots,
  maxParticipantsPerBooking,
  memberships,
}: {
  maxParticipantsPerBooking: number;
  availableSpots: number;
  memberships?: ListEligibleMembershipsResponse;
}) => {
  const remainingCredits: Maybe<number[]> = memberships?.eligibleMemberships
    ?.filter((membership) => membership?.credits?.remaining)
    .map((membership) => membership!.credits!.remaining!);

  const maxRemainingCredits = remainingCredits?.length
    ? Math.max(...remainingCredits!)
    : maxParticipantsPerBooking;

  return Math.min(
    maxParticipantsPerBooking,
    availableSpots,
    maxRemainingCredits,
  );
};

const getServiceName = (service: GetServiceResponse) => {
  return service.service!.info!.name!;
};

const getServiceImagesWithAltText = (service: GetServiceResponse) => {
  return service.service?.info?.images?.map((image) => ({
    ...image,
    altText: getServiceName(service),
  }));
};
